exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-a-guide-to-prototyping-automotive-interfaces-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/a-guide-to-prototyping-automotive-interfaces/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-a-guide-to-prototyping-automotive-interfaces-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-a-new-concept-for-usable-touch-interaction-in-cars-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/a-new-concept-for-usable-touch-interaction-in-cars/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-a-new-concept-for-usable-touch-interaction-in-cars-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-an-in-depth-look-at-the-ux-design-of-the-porsche-taycan-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/an-in-depth-look-at-the-ux-design-of-the-porsche-taycan/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-an-in-depth-look-at-the-ux-design-of-the-porsche-taycan-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-apples-risky-bet-on-carplay-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/apples-risky-bet-on-carplay/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-apples-risky-bet-on-carplay-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-bringing-the-coolest-car-of-the-carrera-panamericana-to-life-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/bringing-the-coolest-car-of-the-carrera-panamericana-to-life/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-bringing-the-coolest-car-of-the-carrera-panamericana-to-life-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-designing-a-new-ux-concept-to-reduce-driver-distraction-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/designing-a-new-ux-concept-to-reduce-driver-distraction/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-designing-a-new-ux-concept-to-reduce-driver-distraction-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-driving-a-porsche-taycan-for-a-week-a-ux-review-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/driving-a-porsche-taycan-for-a-week-a-ux-review/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-driving-a-porsche-taycan-for-a-week-a-ux-review-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-guidelines-for-designing-an-in-car-voice-assistant-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/guidelines-for-designing-an-in-car-voice-assistant/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-guidelines-for-designing-an-in-car-voice-assistant-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-apple-and-google-are-taking-over-the-in-car-user-experience-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/how-apple-and-google-are-taking-over-the-in-car-user-experience/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-apple-and-google-are-taking-over-the-in-car-user-experience-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-waymo-uses-design-to-create-trust-in-driverless-cars-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/how-waymo-uses-design-to-create-trust-in-driverless-cars/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-waymo-uses-design-to-create-trust-in-driverless-cars-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-we-designed-a-white-label-in-car-infotainment-system-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/how-we-designed-a-white-label-in-car-infotainment-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-we-designed-a-white-label-in-car-infotainment-system-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-in-car-climate-control-design-how-it-has-gone-backwards-and-how-to-fix-it-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/in-car-climate-control-design-how-it-has-gone-backwards-and-how-to-fix-it/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-in-car-climate-control-design-how-it-has-gone-backwards-and-how-to-fix-it-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-is-gesture-interaction-a-valid-alternative-to-touch-interaction-in-cars-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/is-gesture-interaction-a-valid-alternative-to-touch-interaction-in-cars/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-is-gesture-interaction-a-valid-alternative-to-touch-interaction-in-cars-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-is-voice-interaction-a-solution-to-driver-distraction-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/is-voice-interaction-a-solution-to-driver-distraction/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-is-voice-interaction-a-solution-to-driver-distraction-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-mercedes-vs-lucid-different-views-on-luxury-ui-design-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/mercedes-vs-lucid-different-views-on-luxury-ui-design/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-mercedes-vs-lucid-different-views-on-luxury-ui-design-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-mixed-reality-the-future-of-automotive-ux-prototyping-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/mixed-reality-the-future-of-automotive-ux-prototyping/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-mixed-reality-the-future-of-automotive-ux-prototyping-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-overcoming-automotive-overconsumption-through-design-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/overcoming-automotive-overconsumption-through-design/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-overcoming-automotive-overconsumption-through-design-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-swiping-vs-tapping-what-is-less-distracting-to-drivers-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/swiping-vs-tapping-what-is-less-distracting-to-drivers/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-swiping-vs-tapping-what-is-less-distracting-to-drivers-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-take-over-moments-the-hardest-design-problem-of-semi-autonomous-cars-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/take-over-moments-the-hardest-design-problem-of-semi-autonomous-cars/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-take-over-moments-the-hardest-design-problem-of-semi-autonomous-cars-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-8-guidelines-for-good-automotive-ux-design-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/the-8-guidelines-for-good-automotive-ux-design/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-8-guidelines-for-good-automotive-ux-design-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-citroen-ami-and-the-future-of-urban-transportation-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/the-citroen-ami-and-the-future-of-urban-transportation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-citroen-ami-and-the-future-of-urban-transportation-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-ehmi-how-autonomous-cars-will-communicate-with-the-outside-world-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/the-ehmi-how-autonomous-cars-will-communicate-with-the-outside-world/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-ehmi-how-autonomous-cars-will-communicate-with-the-outside-world-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-problem-with-digital-instrument-clusters-and-how-to-design-a-better-one-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/the-problem-with-digital-instrument-clusters-and-how-to-design-a-better-one/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-problem-with-digital-instrument-clusters-and-how-to-design-a-better-one-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-process-behind-the-touch-interaction-concept-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/the-process-behind-the-touch-interaction-concept/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-process-behind-the-touch-interaction-concept-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-rise-of-touch-screens-in-cars-explained-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/the-rise-of-touch-screens-in-cars-explained/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-rise-of-touch-screens-in-cars-explained-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-role-of-cognitive-load-in-automotive-ux-design-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/the-role-of-cognitive-load-in-automotive-ux-design/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-role-of-cognitive-load-in-automotive-ux-design-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-state-of-ux-design-of-chinese-car-brands-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/the-state-of-ux-design-of-chinese-car-brands/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-state-of-ux-design-of-chinese-car-brands-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-struggle-to-find-out-how-cars-manage-your-personal-data-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/the-struggle-to-find-out-how-cars-manage-your-personal-data/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-struggle-to-find-out-how-cars-manage-your-personal-data-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-why-car-companies-need-more-interaction-designers-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/why-car-companies-need-more-interaction-designers/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-why-car-companies-need-more-interaction-designers-index-mdx" */)
}

